import Link from "next/link";

import React from "react";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export default function LoginButton() {
  const show_login_button = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.show_login_button),
  );
  if (!show_login_button) return <></>;
  return (
    <Link
      href={`/login`}
      className="block px-4 py-2 text-sm md:text-base a-customer-sign-in-link hover:opacity-80"
    >
      Login
    </Link>
  );
}
