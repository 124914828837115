import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import StoreNav from "../store-nav";
import { useAppDispatch } from "@store/store";
import { setCustomer } from "@store/user-slice";
import { useStoreUser } from "@hooks/store-user";
import Link from "next/link";
import Image from "next/image";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrand,
  selectBrandThemeSetting,
  selectBrandThemeText,
} from "@store/site-slice";
import SeatSelector from "../seat-selector";
import PosActivatedMessage from "../pos-activated-message";
import CartCompleteError from "../cart-complete-error";
import { usePostHog } from "posthog-js/react";
import UtmCampaignDetector from "../utm-campaign-detector";
import Fallback from "../fallback";
import getMenuItemResolvedUrl from "@utils/get-menu-item-resolved-url";
import getMenuItemResolvedLabel from "@utils/get-menu-item-resolved-label";
import ReferrerDetector from "@components/referrer-detector";
import StoreFooter from "@components/store-layout/store-footer";
import { useSearchParams } from "next/navigation";
import { useGetCustomerUserMutation } from "@store/apiSlice";

export default function StoreLayout({ children }: any) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { loggedIn } = useStoreUser();
  const token = useSearchParams().get("token");
  const [getCustomerUser] = useGetCustomerUserMutation();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  useEffect(() => {
    const load = async () => {
      try {
        const token_to_send = token
          ? token
          : localStorage.getItem("customer_api_token");
        if (token_to_send) {
          const customer = await getCustomerUser(token_to_send).unwrap();
          posthog.identify(customer.id, { email: customer.email });
          dispatch(setCustomer(customer));
        }
      } catch (e) {
        // ignore
      }
    };
    if (!loggedIn) {
      load();
    }
  }, [getCustomerUser, dispatch, loggedIn, posthog, token]);

  const brand = useSelector(selectBrand);
  const site_background_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.site_background_color),
  );
  const menu_link_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.menu_link_color),
  );
  const site_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.site_text_color),
  );

  const site_background_image = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.site_background_image),
  );

  const find_my_tickets_label = useSelector(
    selectBrandThemeText(BRAND_THEME_SETTING_KEYS.find_my_tickets_label),
  );

  if (!brand || !site_background_color || !menu_link_color || !site_text_color)
    return <></>;

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor:
          !site_background_image && site_background_color
            ? site_background_color
            : undefined,
        color: site_text_color,
        backgroundImage: site_background_image
          ? `url(${site_background_image})`
          : undefined,
      }}
    >
      <Suspense fallback={<Fallback />}>
        <UtmCampaignDetector />
        <ReferrerDetector />
      </Suspense>
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={`relative max-w-xs w-full shadow-xl pb-12 flex flex-col overflow-y-auto`}
              style={{ backgroundColor: site_background_color }}
            >
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  style={{ color: menu_link_color }}
                  className={`-m-2 p-2 rounded-md inline-flex items-center justify-center`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className={`h-6 w-6`} aria-hidden="true" />
                </button>
              </div>
              <div
                style={{ color: menu_link_color }}
                className={`border-t border-gray-200 py-6 px-4 space-y-6`}
              >
                {brand.pages
                  .filter((p) => p.show_in_menu)
                  .map((page) => (
                    <div
                      key={getMenuItemResolvedLabel(page)}
                      className="flow-root"
                    >
                      <Link
                        onClick={() => setMobileMenuOpen(false)}
                        href={getMenuItemResolvedUrl(brand, page)}
                        className="-m-2 p-2 block font-medium hover:opacity-80"
                      >
                        {getMenuItemResolvedLabel(page)}
                      </Link>
                    </div>
                  ))}
                {brand.show_find_tickets_menu_item && (
                  <div className="flow-root">
                    <Link
                      onClick={() => setMobileMenuOpen(false)}
                      href={`/store/find-my-tickets`}
                      className="-m-2 p-2 block font-medium hover:opacity-80"
                    >
                      {find_my_tickets_label}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative z-10">
        <nav aria-label="Top">
          {/* Top navigation */}
          <PosActivatedMessage />
          <SeatSelector />
          <CartCompleteError />
          {/* Secondary navigation */}
          <div>
            <div
              style={{ color: menu_link_color }}
              className={`border-b border-gray-200`}
            >
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="h-16 md:h-24 flex items-center justify-between">
                  {/* Logo (lg+) */}
                  <div className="hidden lg:flex lg:items-center">
                    <Link href={brand.logo_link_url}>
                      <span className="sr-only">{brand.name}</span>
                      <Image
                        priority={true}
                        className="h-8 w-auto"
                        src={brand.thumbnail}
                        alt=""
                        width={450}
                        height={100}
                      />
                    </Link>
                  </div>

                  <div className="hidden h-full lg:flex">
                    {brand.pages
                      .filter((p) => p.show_in_menu)
                      .map((page) => (
                        <Link
                          key={getMenuItemResolvedLabel(page)}
                          href={getMenuItemResolvedUrl(brand, page)}
                          className={`flex items-center text-base font-medium hover:opacity-80 mr-1 ml-5`}
                        >
                          {getMenuItemResolvedLabel(page)}
                        </Link>
                      ))}
                    {brand.show_find_tickets_menu_item && (
                      <Link
                        href={`/store/find-my-tickets`}
                        className={`flex items-center text-base font-medium hover:opacity-80 mr-1 ml-5`}
                      >
                        {find_my_tickets_label}
                      </Link>
                    )}
                  </div>

                  {/* Mobile menu and search (lg-) */}
                  <div className="flex-shrink flex items-center lg:hidden">
                    <button
                      type="button"
                      style={{ color: menu_link_color }}
                      className={`-ml-2 p-2 rounded-md text-gray-400`}
                      onClick={() => setMobileMenuOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <MenuIcon
                        style={{ color: menu_link_color }}
                        className={`h-6 w-6`}
                        aria-hidden="true"
                      />
                    </button>
                  </div>

                  {/* Logo (lg-) */}
                  <Link href={brand.logo_link_url} className="lg:hidden">
                    <span className="sr-only">JumpComedy.com</span>
                    <Image
                      priority={true}
                      className="h-7 w-auto"
                      src={brand.thumbnail}
                      alt=""
                      width={450}
                      height={100}
                    />
                  </Link>

                  <div className="flex-1 flex items-center justify-end">
                    <div className="flex items-center lg:ml-8">
                      <div className="flow-root">
                        <StoreNav />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <main>{children}</main>

      <StoreFooter
        brand_logo={brand.thumbnail}
        logo_link_url={brand.logo_link_url}
        menu_link_color={menu_link_color}
      />
    </div>
  );
}
